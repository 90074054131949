export default defineNuxtRouteMiddleware(() => {
	// use useRouteGuard() instead
	// Ітс ту слов бекауз ю эдед зис кондишин энд юр код скип сервер парт энд вейтинг вайл клаент сайд из лоадед (c)v.inshakov
	if (!process.client) {
		return;
	}

	const isGuest = useIsGuest();

	if (isGuest.value === true) {
		return navigateTo("/", { external: true });
	}
});
